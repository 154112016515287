import {observable, action, computed} from "mobx";
import EnrollServers from "../../services/EnrollServers";
import {Modal} from 'antd-mobile';
import Config from "../../config";
import WxApi from "../../common/wxapi";
import {toJS} from "mobx/lib/mobx";

class MyEnrollStore {
    @observable myEnrollList;
    @observable currentInfoId;
	@observable currentVerify
    @observable enrollEntry;
	@observable applyRefund
    @observable filedList;
	@observable filedLogic;
    @observable filedInfo;
    @observable cert;
	@observable records;
	@observable custom;
    @observable tid;
    @observable sceneId;
    @observable orderStatus;
    @observable tokenPass;
	@observable authType;
	@observable currItem;
	@observable wxNotice;
	@observable seatInfo;
	@observable rowList;
	@observable itemIndex;
	@observable itemKey;
	@observable seatFee;
	@observable keyList;
	@observable shareImgUrl;
	@observable is_save_info;

    constructor() {
        this.myEnrollList = [];
        this.currentInfoId = 0;
        this.enrollEntry = {};
        this.filedInfo = [];
        this.filedList = [];
		this.filedLogic = [];
        this.selfInfoId = '';
        this.tid = null;
        this.sceneId = null;
        this.orderStatus = false;
        this.tokenPass = false;
		this.authType = 0;
		this.currItem = {};
		this.applyRefund = 0;
		this.wxNotice = {};
		this.seatInfo = {};
		this.rowList = [];
		this.itemIndex = 0;
		this.itemKey = null;
		this.seatFee = 0;
		this.keyList = [];
		this.shareImgUrl = '';
		this.is_save_info = true;
    }

    //详情页面切换显示
    @action
    setMyEnrollList = (v) => {
        this.myEnrollList = v;
    };
	
	 @action
	setWxNotice = (v) => {
		this.wxNotice = v;
	}
	@action
	setShareImg = (v) => {
		this.shareImgUrl = v;
	}
	
	//设置座位信息
	@action
	setSeatInfo = (v) => {
		this.seatInfo = v;
		if(v.booked_seat_table){
			this.setRowList(v.booked_seat_table);
		}
	}

	//座位图坐标
	@action
	setRowList = (v) => {
		this.rowList = v;
	}

    @action
    async setEnrollEntry(v) {
		if(v &&v.items && v.items.length>0){
			for(let b = 0; b < v.items.length; b++){
				let item = v.items[b];
				item.buy = item.count	
			}			 			
		}		 
        this.enrollEntry = v;
		this.setApplyRefund(v.apply_refund);
    }
	@action
	setApplyRefund(v) {
		this.applyRefund = v;
	}
	
    @action
    setCurrentEnroll = (v) => {
        this.currentInfoId = v && v.info_id;
		if(v && v.verified){
			this.currentVerify = v.verified;
		}		
    };
	@action
	setItemList = (v) => {
		this.keyList = v
	}
	@action
	setItemKey = (v) => {
		this.itemKey = v;
	};
	
	@action
	setSeatFee = (v) => {
		this.seatFee = v;
	};
	
	@action
	setItemIndex = (v) => {
		this.itemIndex = v;
	};

    @action
    setSelfInfoId = (v) => {
        this.myEnrollList = v;
    };
    
    @action
    setTid = (v) => {
        this.tid = v;
    };

	@action
    setSceneId = (v) => {
        this.sceneId = v;
    };
    
    @action
    setOrderStatus = (v) => {
        this.orderStatus = v;
    };
    
    @action
    setTokenPass = (v) => {
        this.tokenPass = v;
    };
	
	@action
	setAuthType = (v) => {
	    this.authType = v;
	};
	
	@action
	setCurrItem = (v) => {
	    this.currItem = v;
	};
	
    getMyEnroll(page) {
        const _this = this;
        return EnrollServers.getMyEnroll(page).then((d) => {			
            let result = EnrollServers.checkServerData(d, []);
            _this.setMyEnrollList(result);
            return EnrollServers.checkSuccess(d);
        })
    }
	
	@action
	setFiledList = (v) => {
		this.filedList = v;
		this.initInfo(1);
	}

    @action
    initInfo(type) {
        let filedList = this.filedList;
        let infoList = this.filedInfo;
		let filedLogic = this.filedLogic;
        let result = [];
		if(filedList){
			filedList.map((item) => {
			    let field_value = "";
				let new_field_value = ""
				let ignore = item.ignore
				let other_value = ''
			    if (infoList.length > 0) {
			        let info = infoList.find((i) => item.field_key === i.field_key);					
			        field_value = info && info.field_value;
					new_field_value = info && info.new_field_value					
					if(type != 1 && info){//初始化数据时赋值
						ignore = info.ignore			
						other_value = info.other_value
					}													
			    }
				
				let _field = {
					field_name: item.field_name,
					field_key: item.field_key,
					field_value: field_value,
					currIndex: item.currIndex,
					endIndex: item.endIndex,
				}
				
				let typeList = [4,5,10,11]
				if(typeList.indexOf(item.field_type) !== -1){	
					_field.new_field_value = new_field_value			
				}
	
				if(ignore || ignore == 0){
					_field.ignore = ignore;
				}
				if(other_value)_field.other_value = other_value
			    result.push(_field)
			});
		}
     
        this.filedInfo = result;
    }
	
	@action
	setFiledListInfo(v,info){
		if(v && info){
			v.map((item) => {
				info.map((obj) =>{
					if(item.field_key == obj.field_key){
						if(obj.ignore || obj.ignore == 0){
							item.ignore = obj.ignore;
							item.currIndex = obj.currIndex;
							item.endIndex = obj.endIndex;
							
						}
						
						if(obj.other_value)item.other_value = obj.other_value
					}
				})
			
			})
			// let radioSelectKey = [];
			// v.map((fv,index) => {
			// 	if(fv.field_type == 4 && fv.hasSet){
			// 		let _foption = fv.options;
			// 		_foption.map((oitem) =>{
			// 			if(oitem.text == fv.mField_value){
			// 				fv.currIndex = index;
			// 				// radioSelectKey.push();
			// 			}
			// 		});
			// 	}
				
			// })
			

			this.filedList = v;
		}
	}

    @action
    setInfo(key, value,new_field_value,other_value) {	
        let infoIndex = this.filedInfo.findIndex((i) => i.field_key === key);
        if (infoIndex !== -1) {
            this.filedInfo[infoIndex].field_value = value;

			let typeList = [4,5,10,11]
			if( typeList.indexOf(this.filedList[infoIndex].field_type) != -1){ //单选/多选/下拉框/标签 取新字段key值
				this.filedInfo[infoIndex].new_field_value = new_field_value
			}

			if(other_value){
				this.filedInfo[infoIndex].other_value = other_value
			}else{
				delete this.filedInfo[infoIndex].other_value
			}
		
			if(!this.currentInfoId && this.is_save_info){ //新增时才把数据进行缓存			 
				this.is_save_info = false
				this.saveFiledData()
			}			
        }
    }

    @action
    setDateArr(key, value) {
        this[key] = value;
    }
	saveFiledData(){		 
		setTimeout(()=>{
			this.is_save_info = true;
			sessionStorage.setItem('filedInfo'+Config.EID, JSON.stringify(this.filedInfo));
		}, 3000)
	}
    getEnrollById(infoId) {
		return new Promise(resolve=>{
			const _this = this;
			if (infoId === 0) {
				this.filedInfo = [];
				this.enrollEntry = {};
				this.initInfo();
				return resolve();
			}
			EnrollServers.getEnrollUserListByInfoId(infoId).then((d) => {
				if(d.data.sta == 0){
					let result = EnrollServers.checkServerData(d, {});
					let fromMove = sessionStorage.getItem('fromMove');
					
					if(result && result.info){
					   result.info = result.info.reverse();
					   } 
					if(fromMove){//从转让名额过来编辑报名信息,则之前的用户填写的信息都不需要
						result.info = [];
					}
	   
				   //匹配数据字段
				   _this.setEnrollEntry(result);
				   _this.filedInfo = result.info;
				   _this.filedList = result.req_info;
				   _this.filedLogic = result.req_logic;
				   _this.cancel_before = result.cancel_before;
				   _this.initInfo();
				   _this.setFiledListInfo(result.req_info,result.info);
				   
				   if(result && result.items && result.items.length > 0){
					   _this.setCurrItem(result.items[0])
				   } 
				}else if(d.data.errcode == -7384){// 此名额已被使用  				               
					Modal.alert('温馨提示', '此名额已被使用', [ { text: '我知道了' ,onPress: () => {
						window.location.href = 'https://p.baominggongju.com/detail?eid='+ Config.EID 
					}}])
				}else{
					Modal.alert('温馨提示', '仅发起人和管理员，有权限查看此报名信息', [{ text: '我知道了', onPress: ()=>{
						window.location.href = 'https://p.baominggongju.com/detail?eid='+ Config.EID 
					}}])
				}         			
				return resolve();     			
			})	
		})      
    }

    //保存报名信息
    saveInfo(info, infoId,items) {
        return EnrollServers.saveEnrollInfo(infoId, info,items).then((d) => {
            return EnrollServers.checkSuccess(d);
        })
    }

    //退出报名
    exitEnroll(sub_mchid,infoId,reason,apply_reason) {
        return EnrollServers.exitEnroll(sub_mchid,infoId,reason,apply_reason).then((d) => {
            return EnrollServers.checkSuccess(d);
        })
    }
	
	//取消退出报名
	refundExitEnroll(infoId){
		return EnrollServers.refundExitEnroll(infoId).then((d) => {
		    return EnrollServers.checkSuccess(d);
		})
	}

    //提交报名
    submitEnroll(info, on_behalf, item) {
        return EnrollServers.submitEnroll(info, on_behalf, item).then((d) => {
            return EnrollServers.checkSuccess(d);

        })
    }

    //提交报名
    submitEnrollforPay(info, on_behalf, item,tid,sub_mchid,coupon_info_id) {
		 return EnrollServers.submitEnrollForPay(info, on_behalf, item,tid,sub_mchid,coupon_info_id).then((d) => {
            return EnrollServers.checkSuccess(d);
        })
    }
	
	//提交认证信息
	submitAuthInfo(info,payType) {
		 return EnrollServers.subAuthInfo(info,payType).then((d) => {
	        return EnrollServers.checkSuccess(d);
	    })
	}

	//暂存认证信息
	submitAuthLog(info) {
		return EnrollServers.subAuthLog(info).then((d) => {
	        return EnrollServers.checkSuccess(d);
	    })
	}
	
    //商户平台支付提交
    submitBusinessPay(tmp_id) {
		 return EnrollServers.submitBusinessPay(tmp_id).then((d) => {
            return EnrollServers.checkSuccess(d);
        })
       
    }
    

    getEnrollCert(info) {
        let _this = this;
        return EnrollServers.getEnrollCert(info).then((d) => {
            let result = EnrollServers.checkServerData(d, {});		
            action(() => {
                _this.cert = result;
				_this.records = result.records
				if(result.temp == 10){ //凭证需要
					_this.records = _this.records.map(item=>{
						for(let a = 0; a < item.items.length; a++){
							if(item.items[a].choose_booked && item.items[a].choose_booked.length){						 
								item.items[a].seatText = WxApi.setSeatInfo(item.items[a].choose_booked, result.items[0]);  								 						 
							}                                
						 }                            
						 return item;  
					})
				}
				let params = {cert:result,records:result.records}
                sessionStorage.setItem('certInfo',JSON.stringify(params))  			    
            })();
        })
    }
	
	//查询自定义报名详情
	getEnrollCustom() {
	    let _this = this;
	    return EnrollServers.getEnrollCustom().then((d) => {
	        let result = EnrollServers.checkServerData(d, {});
	        action(() => {
	            _this.custom = result;
	        })();
	    })
	}
	
	

    //上传图片
    uploadImage(media_id) {
        let _this = this;
        return EnrollServers.uploadImage(media_id).then((d) => {
            let result = EnrollServers.checkSuccess(d, {});
            return result;
        })
    }


    //上传Base64
    uploadBase64(base64) {
        let _this = this;
        return EnrollServers.uploadBase(base64).then((d) => {
            let result = EnrollServers.checkSuccess(d, {});
            return result;
        })
    }
    
    //提交举报信息
    submitReport(reason){
    	
    	 return EnrollServers.submitReport(reason).then((d) => {
            return EnrollServers.checkSuccess(d);
        })
    }
    
    //获取订阅消息场景ID
    getSceneId(info) {
        let _this = this;
        return EnrollServers.getSceneId().then((d) => {
            let result = EnrollServers.checkServerData(d, {});
            _this.setSceneId(result.scene);
        })
    }
    
    //订阅通知
    subNotice(scene_id,openid){
    	let _this = this;
        return EnrollServers.subNotice(scene_id,openid).then((d) => {
        	let result = EnrollServers.checkServerData(d, {});
        	if(d.data.sta ==0){
        		_this.setOrderStatus(true);
        	}
        	
//           return EnrollServers.checkSuccess(d);
        })
    }
    
    
    subToken(token){
    	let _this = this;
    	return EnrollServers.subToken(token).then((d) => {
        	let result = EnrollServers.checkServerData(d, {});
        	console.log("-----result:   ",d.data.data.result)
        	if(d.data.sta ==0 && d.data.data.result == 1){
        		_this.setTokenPass(true);
        	}else{
        		_this.setTokenPass(false);
        	}
        })
    }
	
	getPhoneCode(phone){
		return EnrollServers.getCode(phone).then((d) => {
		    return EnrollServers.checkSuccess(d);
		})
	}
	// 免登录获取验证码
	getPwdCode(phone){
		return EnrollServers.getPwdCode(phone).then((d) => {
		    return EnrollServers.checkSuccess(d);
		})
	}
	//提交认证信息
	validateCode(code) {
		 return EnrollServers.validateCode(code).then((d) => {
	        return EnrollServers.checkSuccess(d);
	    })
	}
	
	//添加留言
	addMessage(content,device,c_pic) {
		return EnrollServers.addMessage(content,device,c_pic).then((d) => {
	        return EnrollServers.checkSuccess(d);
	    })
	}

	//删除留言
	deleteMessage(msg_id) {
		return EnrollServers.deleteMessage(msg_id).then((d) => {
	        return EnrollServers.checkSuccess(d);
	    })
	}
	// 回复留言
	replayMessage(params){
		return EnrollServers.replayMessage(params).then((d) => {
	        return EnrollServers.checkSuccess(d);
	    })
	}
	// 置顶 or 公开留言
	setMsgControl(params){
		return EnrollServers.setMsgControl(params).then((d) => {
	        return EnrollServers.checkSuccess(d);
	    })
	}
	
	//获取座位渲染图
	getSeatInfo(key) {
	    const _this = this;
	    return EnrollServers.getSeatInfo(key).then((d) => {
	        let result = EnrollServers.checkServerData(d, []);
	        _this.setSeatInfo(result);
	        return result
	    })
	}
	
	// 最近浏览
	getEnrollHistory(){
		return EnrollServers.getEnrollHistory().then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}

	// 我管理的&我参与的
	getManageList(params){
		return EnrollServers.getManageList(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}

	// 公开活动
	toPublicEnroll(params){
		return EnrollServers.toPublicEnroll(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 获取个人主页详情
	getHomePageData(unionid){
		return EnrollServers.getHomePageData(unionid).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 获取个人主页详情 (免登录)
	getHomePageV2(unionid){
		return EnrollServers.getHomePageV2(unionid).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 个人主页推荐列表
	recommendHomePage(){
		return EnrollServers.recommendHomePage().then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 获取公开活动列表
	getEnrollList(params){
		return EnrollServers.getEnrollList(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// h5添加订阅消息次数
	addSubNotice(type){
		return EnrollServers.addSubNotice(type).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 我关注的活动列表
	getSubList(params){
		return EnrollServers.getSubList(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 删除快速填写信息
	deleteExtraInfo(params){
		return EnrollServers.deleteExtraInfo(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	//保存快速填写信息
	saveExtraInfo(params){
		return EnrollServers.saveExtraInfo(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 获取扫码登录的二维码
	getLoginCode(){
		return EnrollServers.getLoginCode().then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 扫码登录
	scanCodeLogin(code){
		return EnrollServers.scanCodeLogin(code).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 消息提醒
	switchNotice(params,type="send"){
		return EnrollServers.switchNotice(params,type).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 会员卡详情1
	vipCardDetail(params){
		return EnrollServers.vipCardDetail(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 
	}
	// 会员卡列表
	vipCardList(params){
		return EnrollServers.vipCardList(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 
	}
	// 购买会员卡
	payVipCard(params){
		return EnrollServers.payVipCard(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 
	}
	// 会员卡续费
	toRenewVip(params){
		return EnrollServers.toRenewVip(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 
	}
	// 查看会员详情
	getVipUserDetail(out_trade_no,vip_id){
		return EnrollServers.getVipUserDetail(out_trade_no,vip_id).then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 
	}
	// 会员卡购买记录
	getVipList(params){
		return EnrollServers.getVipList(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 
	}
	// 清除最近浏览记录
	delHistory(){
		return EnrollServers.delHistory().then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 
	}
	// 查看是否开启企业微信提醒
	getWorkConfig(){
		return EnrollServers.getWorkConfig().then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 
	}

	// 注销账号
	toCancelUser(){
		return EnrollServers.toCancelUser().then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 
	}
	// h5获取ticket
	getShortTicket(params){
		return EnrollServers.getShortTicket(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 
	}

	// 手机号登录
	toPhoneLogin(params){
		return EnrollServers.toPhoneLogin(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 
	}

	//退款记录  
	getRefundList(params){
		return EnrollServers.getRefundList(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 
	}

	// 退款详情
	getRefundDetail(params){
		return EnrollServers.getRefundDetail(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 
	}

	//获取上次提交记录
	getPeriodDetail(){
		return EnrollServers.getPeriodDetail().then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 
	}

	//编辑个人信息
	editUserMessage(params,type){
		return EnrollServers.editUserMessage(params,type).then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 
	}

	// 获取团购订单
	getCartOrder(params){
		return EnrollServers.getCartOrder(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 
	}

	// 收款码付款
	toCodePay(params){
		return EnrollServers.toCodePay(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 
	}

	// 收款商户信息
	getCashierDetail(unionid,type,eid){
		return EnrollServers.getCashierDetail(unionid,type,eid).then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 
	}

	// 我的优惠券列表
	getCouponList(data){
		return EnrollServers.getCouponList(data).then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 
	}

	// 发起续费代付 
	getReplaceInfo(params){
		return EnrollServers.getReplaceInfo(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 
	}

	// 代付拉起支付
	applyOtherPay(out_trade_no){
		return EnrollServers.applyOtherPay(out_trade_no).then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 
	}

	// 删除留言
	toDeleteMsg(msg_id,reply_id){
		return EnrollServers.toDeleteMsg(msg_id,reply_id).then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 	
	}
	
	searchMyEnroll(){
		return EnrollServers.searchMyEnroll().then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 	
	}

	// 生成分享SchemeUrl
	getSchemeUrl(params){
		return EnrollServers.getSchemeUrl(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 	
	}

	// 提交确认会员数据
	toVerifyVip(params){
		return EnrollServers.toVerifyVip(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 	
	}

	 // 我加入的会员列表
	getMyVipCard(){
		return EnrollServers.getMyVipCard().then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 转让参与记录
	toMoveInfo(info,info_id){
		return EnrollServers.toMoveInfo(info,info_id).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 用户标记数据分享
	toShareInfo(data){
		return EnrollServers.toShareInfo(data).then((d)=>{                 			
			return EnrollServers.checkSuccess(d);
		})
    }
	// 钉钉消息配置
	getDingConfig(){
		return EnrollServers.getDingConfig().then((d)=>{                 			
			return EnrollServers.checkSuccess(d);
		})
	}
	// 获取编辑历史记录
	getEditHistory(params){
		return EnrollServers.getEditHistory(params).then((d)=>{                 			
			return EnrollServers.checkServerData(d);
		})
	}
	// 免登录查看付款价格配置
	getPriceList(unionid){
		return EnrollServers.getPriceList(unionid).then((d)=>{                 			
			return EnrollServers.checkSuccess(d);
		})
	}
	//回收站列表
	getBackList(params){
		return EnrollServers.getBackList(params).then((d)=>{                 			
			return EnrollServers.checkSuccess(d);
		})		
	}
	// 恢复活动
	toRecoverEnroll(params){
		return EnrollServers.toRecoverEnroll(params).then((d)=>{                 			
			return EnrollServers.checkSuccess(d);
		})	
	}	
	// 彻底删除活动
	toDeleteEnroll(params){
		return EnrollServers.toDeleteEnroll(params).then((d)=>{                 			
			return EnrollServers.checkSuccess(d);
		})	
	}	
	// 绑定手机号
	toBindPhone(params){
		return EnrollServers.toBindPhone(params).then((d)=>{                 			
			return EnrollServers.checkSuccess(d);
		})	
	}
	// 解绑手机号
	toUnbindPhone(){
		return EnrollServers.toUnbindPhone().then((d)=>{                 			
			return EnrollServers.checkSuccess(d);
		})	
	}	
	// 重置密码 
	toResetPwd(params){
		return EnrollServers.toResetPwd(params).then((d)=>{                 			
			return EnrollServers.checkSuccess(d);
		})	
	}
	// 换绑手机号
	toChangePhone(params){
		return EnrollServers.toChangePhone(params).then((d)=>{                 			
			return EnrollServers.checkSuccess(d);
		})	
	}
	// 授权code，获取openid
	getOpenid(params){
		return EnrollServers.getOpenid(params).then((d)=>{                 			
			return EnrollServers.checkSuccess(d);
		})	
	}
	// 下单支付测试
	toPayTest(params){
		return EnrollServers.toPayTest(params).then((d)=>{                 			
			return EnrollServers.checkSuccess(d);
		})	
	}
	// 注册签名(测试支付，无token)
	toPaySign(params){
		return EnrollServers.toPaySign(params).then((d)=>{                 			
			return EnrollServers.checkSuccess(d);
		})	
	}
	// 核销签到
	toCheckCert(params){
		return EnrollServers.toCheckCert(params).then((d)=>{                 			
			return EnrollServers.checkSuccess(d);
		})	
	}
	// 校验手机号
	checkPhone(params){
        return EnrollServers.checkPhone(params).then((d)=>{                 			
			return EnrollServers.checkSuccess(d);
		})	
    }
	// 会员登录
	vipToLogin(unionid){
		return EnrollServers.vipToLogin(unionid).then((d)=>{                 			
			return EnrollServers.checkSuccess(d);
		})	
	}
	// 订单投诉
	orderComplaint(params,type){
		return EnrollServers.orderComplaint(params,type).then((d)=>{                 			
			return EnrollServers.checkSuccess(d);
		})	
	}
	// 撤销投诉
	cancelComplaint(params){
		return EnrollServers.cancelComplaint(params).then((d)=>{                 			
			return EnrollServers.checkSuccess(d);
		})	
	}
	// 获取消息通知
	getNoticeAll(params){
		return EnrollServers.getNoticeAll(params).then((d)=>{     
			let result = EnrollServers.checkServerData(d)
			this.setWxNotice(result);          			
			return EnrollServers.checkSuccess(d);
		})	
	}
	// 获取最近六个月参与记录
	getJoinList(params){
		return EnrollServers.getJoinList(params).then((d)=>{                 			
			return EnrollServers.checkSuccess(d);
		})
	}
	// 转载活动
	reshippedEnroll(params){
		return EnrollServers.reshippedEnroll(params).then((d)=>{                 			
			return EnrollServers.checkSuccess(d);
		})
	}
	// 邀请参团
	toShareGroup(params){
		return EnrollServers.toShareGroup(params).then((d)=>{                 			
			return EnrollServers.checkSuccess(d);
		})
	}
	// 转让模块
	getShareList(params){
		return EnrollServers.getShareList(params).then((d)=>{                 			
			return EnrollServers.checkSuccess(d);
		})
	}
	// 获取共创活动列表
	getTeamUser(params){
		return EnrollServers.getTeamUser(params).then((d)=>{                 			
			return EnrollServers.checkSuccess(d);
		})
	}
	// 获取分享口令
	getSearchToken(){
		return EnrollServers.getSearchToken().then((d)=>{                 			
			return EnrollServers.checkSuccess(d);
		})
	}
	// 发送投诉留言
	sendComplaintMsg(params){
		return EnrollServers.sendComplaintMsg(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 投诉留言列表
	getComplaintList(params){
		return EnrollServers.getComplaintList(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 订单详情
	getOrderDetail(params){
		return EnrollServers.getOrderDetail(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 获取商户信息
	getMerchantMsg(){
		return EnrollServers.getMerchantMsg().then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 查询进行中活动的数量
	getEnrollNum(){
		return EnrollServers.getEnrollNum().then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 一键截止所有活动
	closeEnrollAll(){
		return EnrollServers.closeEnrollAll().then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// VIP权益转让
	authReplace(unionid){
		return EnrollServers.authReplace(unionid).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 管理员记录
	adminHistory(){
		return EnrollServers.adminHistory().then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 转移商户
	toBindMch(params){
		return EnrollServers.toBindMch(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 商户转移记录
	getMchLog(params){
		return EnrollServers.getMchLog(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}	
	// 仅微信群聊可参与
	getShareGroup(params){
		return EnrollServers.getShareGroup(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// VIP购买的订单记录
	getAuthOrder(params){
		return EnrollServers.getAuthOrder(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 回收站中恢复某个活动的数据
	toBakPay(params){
		return EnrollServers.toBakPay(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		}) 
	}
	// 账号安全校验code
	getRiskCode(params){
		return EnrollServers.getRiskCode(params).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 回收站复制创建活动
	getCopyPay(eid){
		return EnrollServers.getCopyPay(eid).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 获取黑名单详情
	getBlackDetail(unionid){
		return EnrollServers.getBlackDetail(unionid).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 提交申诉
	toAppeal(unionid){
		return EnrollServers.toAppeal(unionid).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 获取申诉详情
	getAppealDetail(unionid){
		return EnrollServers.getAppealDetail(unionid).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}	
	// 白名单校验	
	toCheckWhite(data){
		return EnrollServers.toCheckWhite(data).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 商户投诉列表
	getComplaintData(data){
		return EnrollServers.getComplaintData(data).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 订单投诉列表
	getMyComplaintList(data){
		return EnrollServers.getMyComplaintList(data).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	//  付款记录
	getRecordPay(data){
		return EnrollServers.getRecordPay(data).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 商户投诉详情
	getComplaintHistory(data){
		return EnrollServers.getComplaintHistory(data).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 新用户提交时保存头像昵称
	saveNewUser(data){
		return EnrollServers.saveNewUser(data).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 导出pdf数据
	getPdfImg(info_id){
		return EnrollServers.getPdfImg(info_id).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
	// 未登录情况下查看商户收款码
	getMerchantDetail(unionid){
		return EnrollServers.getMerchantDetail(unionid).then((d)=>{
			return EnrollServers.checkSuccess(d);
		})
	}
}


const myEnrollStore = new MyEnrollStore();

export {myEnrollStore};
