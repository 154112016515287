import React, {Component} from "react";
import { Modal, Button } from "antd-mobile";
import WxApi from "../common/wxapi";
import Config from "../config/index";
import { inject, observer } from 'mobx-react';

@inject("enrollStore")
@observer
class ManageDialog extends Component{
    state = {
        showManage: false,
    };
    constructor(props){
        super(props); 
    };
    componentWillReceiveProps(props){     
        this.setState({showManage: props.showManage});   
    }   
    render(){
        let {detail} = this.props.enrollStore;		
        const miniBtnStyle = {
			position: 'absolute',
			left: '0',
			top: '0',
			width: '100%',
			height: '100%',
		}
		return (
			<Modal visible={this.state.showManage} transparent={true} animationType="slide-up" popup className="manage-menu-dialog" onClose={()=>{
				this.props.close()		 
			}}>
				<div className='manage-header'>
					<img className='web-img' src='https://cdn-xcxcustom.weiyoubot.cn/20210608/542f165ee3f7fe3ff27f215d3b951e03.png'></img>
					<div className='header-main'>
						<span className='warn-tips'>电脑端创建编辑更方便</span>
						<a className='web-url' target='_blank' href='http://baominggongju.com'>https://baominggongju.com</a>
					</div>
					<Button className='operate-btn' onClick={()=>{
						window.open('http://baominggongju.com')
					}}>去试试</Button>
				</div>
				<ul className='menu-list' style={{position: 'relative'}} onClick={()=>{
					this.props.open();		 		 
				}}>
					{
						detail.is_admin != 2 ?
						<li className='menu-items'>
							<img className='menu-img' src='https://cdn-xcx-qunsou.weiyoubot.cn/material/20220516/02ec2cab65ec0856e06ebde7aa271c23.png'></img>
							<span>编辑修改</span>
						</li> : null
					}
					{
						detail.is_admin != 2 ?
						<li className='menu-items'>
							<img className='menu-img' src='https://cdn-xcx-qunsou.weiyoubot.cn/material/20220516/649c3c52576527291ccd447033354057.png'></img>
							<span>复制创建</span>
						</li> : null
					}										
					<li className='menu-items'>
						<img className='menu-img' src='https://cdn-xcx-qunsou.weiyoubot.cn/material/20220516/ad5e8d7078430e282078805782de1272.png'></img>
						<span>导出数据</span>
					</li>
					{
						detail.is_owner ?
						<li className='menu-items'>
							<img className='menu-img' src='https://cdn-xcx-qunsou.weiyoubot.cn/material/20220516/010ceb70622e5501c077e3315ff19754.png'></img>
							<span>删除</span>
						</li> : null
					}
					{
						detail.is_admin != 2 ?
						<li className='menu-items'>
							<img className='menu-img' src='https://cdn-xcx-qunsou.weiyoubot.cn/material/20220516/e7504a632122578777f4863a280a8fdb.png'></img>
							<span>发送通知</span>
						</li> : null
					}	
					{
						detail.is_admin != 2 ?
						<li className='menu-items'>
							<img className='menu-img' src='https://cdn-xcx-qunsou.weiyoubot.cn/material/20220516/01e879b71eb8b8a94c1e87f6c42ac45c.png'></img>
							<span>收款记账</span>
						</li> : null
					}				
					{
						detail.is_owner ? 
						<li className='menu-items'>
							<img className='menu-img' src='https://cdn-xcx-qunsou.weiyoubot.cn/material/20220516/7bf04ae8df44cc64ac1420f2a9db69f4.png'></img>
							<span>设置管理员</span>
						</li>
						: 
						<li className='menu-items'>
							<img className='menu-img' src='https://cdn-xcx-qunsou.weiyoubot.cn/material/20220516/7bf04ae8df44cc64ac1420f2a9db69f4.png'></img>
							<span>{detail.is_admin == 1 ? '退出管理员' : '退出数据员'}</span>
						</li>
					}				 
					<li className='menu-items'>
						<img className='menu-img' src='https://cdn-xcx-qunsou.weiyoubot.cn/material/20220516/8447b567215cbe1396fe992bdfffb27b.png'></img>
						<span>核销签到</span>
					</li>
					{
						detail.is_owner || detail.is_admin == 1 ?
						<li className='menu-items'>
							<img className='menu-img' src='https://cdn-xcx-qunsou.weiyoubot.cn/material/20230817/588c16fd908b506f97194ab93ccb3552.png'></img>
							<span>创建查询</span>
						</li> : null
					}		
					{
						detail.is_admin != 2 ?
						<li className='menu-items'>
							<img className='menu-img' src={
								detail.status == 1 ? 'https://cdn-xcx-qunsou.weiyoubot.cn/material/20220516/bf5787477274cd334a1463ebcf151ecb.png' 
								: 'https://cdn-xcx-qunsou.weiyoubot.cn/material/20220516/02865f2a3dbd777ba63bb7072daa0973.png'
							}></img>
							<span>{detail.status == 1 ? '截止活动' : '开启活动'}</span>
						</li> : null
					}								
					{
						detail.is_owner ?
						<li className='menu-items'>
							<img className='menu-img' src='https://cdn-xcx-qunsou.weiyoubot.cn/material/20220516/3d1cde1ae018d8afe522827618ee17ba.png'></img>
							<span>会员管理</span>
						</li> : null
					}					
					{
						WxApi.isWeixn() && WxApi.isMobile() ? <div className="btn-open-mini-opacity">
							<wx-open-launch-weapp
									style={miniBtnStyle}
									id="launch-btn"
									username="gh_ae6449501a82"
									path={detail.eid ? this.props.wxJumpPath : '/pages/default/default.html'}
								>
								<script type="text/wxtag-template">
									<div style={miniBtnStyle}></div>
								</script>
							</wx-open-launch-weapp>
						</div> : null
					}		
				</ul>
				<Button className='cancel-btn' onClick={()=>{
					this.props.close() 				 
				}}>取消</Button>
			</Modal>
		)
    }
}


export default ManageDialog;